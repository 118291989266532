import React from "react";
import ComingSoon from "../../components/ComingSoon";

const ComingSoonPage = () => {
  return (
    <ComingSoon />
  );
};

export default ComingSoonPage;
