import React from "react";
import {
  Card,
  Typography
} from 'antd';
import { withTranslation } from 'react-i18next';
import { Link } from "react-router-dom";
import { Home, Package, Shield } from "lucide-react";

const { Title } = Typography;

const AccountSetting = ({ t }) => {
  return (
    <div>
      <div>
        <Title level={2}>
          {t('yourAccount')}
        </Title>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        <Card hoverable className="flex flex-col">
          <Link
            className="custom-links"
            to={"/orders"}
            style={{ textDecoration: "none" }}>
            <div className="flex items-start gap-4">
              <div className="bg-[rgb(147,197,253)] p-4 rounded-full">
                <Package className="w-6 h-6 text-white" />
              </div>
              <div>
                <h3 className="text-xl font-semibold mb-2">{t('profilePage.order')}</h3>
                <p className="text-gray-600">
                  {t('profilePage.orderDescription')}
                </p>
              </div>
            </div>
          </Link>
        </Card>

        <Card hoverable className="flex flex-col">
          <Link
            className="custom-links"
            style={{ textDecoration: "none" }}
            to={"/profile"}
          >
            <div className="flex items-start gap-4">
              <div className="bg-[rgb(147,197,253)] p-4 rounded-full">
                <Shield className="w-6 h-6 text-white" />
              </div>
              <div>
                <h3 className="text-xl font-semibold mb-2">{t('profilePage.login')}</h3>
                <p className="text-gray-600">
                  {t('profilePage.loginDescription')}
                </p>
              </div>
            </div>
          </Link>
        </Card>

        <Card hoverable className="flex flex-col">
          <Link
            to={"/addresses"}
            className="custom-links"
            style={{ textDecoration: "none" }}>
            <div className="flex items-start gap-4">
              <div className="bg-[rgb(147,197,253)] p-4 rounded-full">
                <Home className="w-6 h-6 text-white" />
              </div>
              <div>
                <h3 className="text-xl font-semibold mb-2">{t('profilePage.addresses')}</h3>
                <p className="text-gray-600">
                  {t('profilePage.addressesDescription')}
                </p>
              </div>
            </div>
          </Link>
        </Card>
      </div>
    </div>
  );
};

export default withTranslation()(AccountSetting);