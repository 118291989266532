import React from "react";
import Favorite from "../../components/Favorite";

const FavoritePage = () => {
  return (
      <Favorite />
  );
};

export default FavoritePage;
