import React, { useEffect } from "react";
import { Modal, Form, Input, Select, Button } from 'antd';
import { useMutation } from "@tanstack/react-query";
import cogoToast from "cogo-toast";
import queryClient from "../../services/react-query/queryClient";
import { queryKeys } from "../../services/react-query/queryKeys";
import { addAddress, updateAddress } from "../../network/address";
import { withTranslation } from "react-i18next";

const ModalAddress = ({ t, visible, onClose, editingAddress, onAddressAdded }) => {
  const [form] = Form.useForm();
  useEffect(() => {
    if (visible) {
      if (editingAddress) {
        form.setFieldsValue(editingAddress);
      } else {
        form.resetFields();
      }
    }
  }, [visible, editingAddress, form]);

  const { mutate: handleAddOrUpdateAddress, isPending } = useMutation({
    mutationFn: (values) => {
      if (editingAddress) {
        return updateAddress(editingAddress.id, values);
      }
      return addAddress(values);
    },
    onSuccess: (data) => {
      if (data.status === "true") {
        cogoToast.success(
          editingAddress ? t("messages.addressUpdatedSuccessfully") : t("messages.addressAddedSuccessfully"),
          { position: "top-right" }
        );
        queryClient.invalidateQueries([queryKeys.listAddresses]);
        onClose();
        form.resetFields();
        if (!editingAddress && onAddressAdded) {
          onAddressAdded();
        }
      } else {
        cogoToast.error(t("messages.errorOccurred"), { position: "top-right" });
      }
    },
    onError: () => {
      cogoToast.error(t("messages.errorOccurred"), { position: "top-right" });
    },
  });

  const onFinish = (values) => {
    values.is_current_address = true;
    handleAddOrUpdateAddress(values);
  };

  return (
    <Modal
      title={editingAddress ? t("messages.editAddress") : t("messages.addAddress")}
      open={visible}
      onCancel={() => {
        form.resetFields();
        onClose();
      }}
      footer={null}
    >
      <Form form={form} layout="vertical" onFinish={onFinish} initialValues={editingAddress}>
        <Form.Item
          name="country"
          label={t("messages.country")}
          required={false}
          initialValue="Egypt"
          rules={[{ required: true, message: t("messages.requiredField") }]}
        >
          <Select defaultValue="Egypt">
            <Select.Option value="Egypt">Egypt</Select.Option>
          </Select>
        </Form.Item>

        <Form.Item
          name="city"
          label={t("messages.city")}
          required={false}
          rules={[{ required: true, message: t("messages.requiredField") }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="street"
          label={t("messages.streetAddress")}
          required={false}
          rules={[{ required: true, message: t("messages.requiredField") }]}
        >
          <Input />
        </Form.Item>

        <Form.Item className="mb-0">
          <div className="flex justify-end gap-2">
            <Button
              onClick={() => {
                form.resetFields();
                onClose();
              }}
            >
              {t("messages.cancel")}
            </Button>
            <Button loading={isPending} type="primary" htmlType="submit" className="bg-blue-600">
              {editingAddress ? t("messages.update") : t("add")} {t("messages.address")}
            </Button>
          </div>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default withTranslation()(ModalAddress);
