import React, { useEffect } from 'react';
import CartReview from './CartReview';
import { withTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { resetSteps } from '../../services/store/slices/cartSlice';
import Checkout from './Checkout';

const Cart = ({ t, i18n }) => {
  const currentStep = useSelector((state) => state.cart.currentStep);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(resetSteps());
    localStorage.removeItem('currentStep');
  }, [dispatch]);

  const steps = [
    { title: t('messages.cartReview'), content: <CartReview t={t} i18n={i18n} /> },
    { title: t('messages.payment'), content: <Checkout t={t} i18n={i18n} /> },
  ];

  const validStep = steps[currentStep] ? steps[currentStep] : steps[0];

  return (
    <div>{validStep.content}</div>
  );
};

export default withTranslation()(Cart);
