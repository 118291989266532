import React from "react";
import AccountSetting from "../../components/AccountSetting";

const AccountSettingPage = () => {
  return (
    <AccountSetting />
  );
};

export default AccountSettingPage;
