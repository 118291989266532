import AllOfferPage from "../pages/Offer";
import OfferDetailsPage from "../pages/OfferDetails";

const routesOffer = [
  {
    path: "/offers",
    element: <AllOfferPage />,
  },
  {
    path: "/offers/details",
    element: <OfferDetailsPage />,
  },
];

export default routesOffer;
