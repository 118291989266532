import React from "react";
import Addresses from "../../components/Addresses";

const AddressesPage = () => {
  return (
    <Addresses />
  );
};

export default AddressesPage;
