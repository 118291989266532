import ProductPage from "../pages/Product";
import ProductCategoryPage from "../pages/ProductCategory";
import ProductDetailsPage from "../pages/ProductDetails";
import SearchPage from "../pages/Search";

const routesProduct = [
  {
    path: "/products",
    element: <ProductPage />,
  },
  {
    path: "/products/details",
    element: <ProductDetailsPage />,
  },
  {
    path: "/products/categories",
    element: <ProductCategoryPage />,
  },
  {
    path: "/search",
    element: <SearchPage />,
  },
];

export default routesProduct;
