import {axiosInstance} from "../services/axiosInstance";
import catchAsync from "../utils/catchAsync";
import i18next from "i18next";

export const listAddresses = catchAsync(async () => {
  const { data } = await axiosInstance.get('addresses', {
    headers: {
      "Accept-Language": i18next.language,
    }
  });
  return data;
});

export const addAddress = catchAsync(async (body) => {
  const { data } = await axiosInstance.post("addresses", body);
  return data;
});

export const updateAddress = catchAsync(async (id, body) => {
  const { data } = await axiosInstance.put(`addresses/${id}`, body);
  return data;
});

export const setCurrentAddress = catchAsync(async (id) => {
  const { data } = await axiosInstance.get(`addresses/${id}/current`);
  return data;
});


export const removeAddress = catchAsync(async (id) => {
  const { data } = await axiosInstance.delete(`addresses/${id}`);
  return data;
});