import React from 'react';
import { Flex, Spin } from 'antd';

const SpinLoading = () => {
  return (
    <Flex className="my-40" align="center" gap="middle" justify="center" >
      <Spin size="large" />
    </Flex>
  );
};

export default SpinLoading;
