import { Truck, CreditCard, Gift } from "lucide-react";
import React, { useEffect, useState } from "react";
import { Button, Card, Form, Typography, Input, Space } from "antd";
import { withTranslation } from "react-i18next";
import { motion } from "framer-motion";
import { Package } from "lucide-react";
import { useMutation } from "@tanstack/react-query";
import { contactUs } from "../../network/contact";
import cogoToast from "cogo-toast";

const { Title, Text } = Typography;

const ComingSoon = ({ t }) => {
  const [form] = Form.useForm();

  const { mutate: onContact, isPending } = useMutation({
    mutationFn: (formData) => contactUs(formData),
    onSuccess: (data) => {
      form.resetFields();
      cogoToast.success(t("messages.messageSentSuccessfully"), { position: "top-right" });
    },
    onError: () => {
      cogoToast.warn(t("messages.warningOccurred"), { position: "top-right" });
    },
  });

  const onFinish = (values) => {
    const enhancedValues = {
      ...values,
      form_name: "coming_soon_app",
    };
    onContact(enhancedValues);
  };

  const [timeLeft, setTimeLeft] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });
  useEffect(() => {
    const timer = setInterval(() => {
      const launch = new Date("2024-03-01").getTime();
      const now = new Date().getTime();
      const difference = launch - now;
      setTimeLeft({
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor(
          (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
        ),
        minutes: Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60)),
        seconds: Math.floor((difference % (1000 * 60)) / 1000),
      });
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  return (
    <div className="max-w-6xl mx-auto">
      <div className="text-center mb-16">
        <Title level={1} className="text-5xl md:text-6xl font-bold mb-6">
          {t('coming')}
        </Title>

        <Title level={3} className="font-normal text-gray-600 mb-8">
          {t('available')}
        </Title>

        <div className="flex justify-center gap-4 mb-12">
          <Button disabled className="h-12 w-40 opacity-50">
            <img
              src="https://upload.wikimedia.org/wikipedia/commons/3/3c/Download_on_the_App_Store_Badge.svg"
              alt="App Store"
              className="w-full h-full object-contain"
            />
          </Button>
          <Button disabled className="h-12 w-40 opacity-50">
            <img
              src="https://upload.wikimedia.org/wikipedia/commons/7/78/Google_Play_Store_badge_EN.svg"
              alt="Play Store"
              className="w-full h-full object-contain"
            />
          </Button>
        </div>

        <div className="flex justify-center gap-8 mb-16">
          <div className="text-center">
            <Text className="text-3xl font-bold text-[#0052FF]">{timeLeft.days}</Text>
            <Text className="block text-gray-600">{t('days')}</Text>
          </div>
          <div className="text-center">
            <Text className="text-3xl font-bold text-[#0052FF]">{timeLeft.hours}</Text>
            <Text className="block text-gray-600">{t('hours')}</Text>
          </div>
          <div className="text-center">
            <Text className="text-3xl font-bold text-[#0052FF]">{timeLeft.minutes}</Text>
            <Text className="block text-gray-600">{t('minutes')}</Text>
          </div>
          <div className="text-center">
            <Text className="text-3xl font-bold text-[#0052FF]">{timeLeft.seconds}</Text>
            <Text className="block text-gray-600">{t('seconds')}</Text>
          </div>
        </div>

        <div className="max-w-md mx-auto mb-16">
          <Form
            form={form}
            name="contact"
            onFinish={onFinish}
            layout="vertical"
          >
            <Form.Item
              name="email"
              rules={[
                { type: 'email', message: t("auth.emailValidation") },
                { required: true, message: t("auth.emailValidationRequired") },
              ]}
            >
              <Space.Compact size="large" style={{ width: "100%" }}>
                <Input placeholder={t("enterEmail")} />
                <Button type="primary" htmlType="submit" loading={isPending}>
                  {t("notifyMe")}
                </Button>
              </Space.Compact>
            </Form.Item>
          </Form>
        </div>
      </div>

      <div className="grid gap-6 sm:grid-cols-4">
        <motion.div
          whileHover={{
            scale: 1.03,
          }}
          transition={{
            duration: 0.2,
          }}
        >
          <Card className="group h-full shadow-sm hover:shadow-lg">
            <div className="mb-2 inline-block rounded-lg">
              <Package className="h-6 w-6 text-blue-600" />
            </div>
            <h3 className="mb-2 text-xl font-semibold">
              <p>{t('home.qualityProducts')}</p>
            </h3>
            <p className="text-gray-600">
              {t('home.qualityProductsDescription')}
            </p>
          </Card>
        </motion.div>

        <motion.div
          whileHover={{
            scale: 1.03,
          }}
          transition={{
            duration: 0.2,
          }}
        >
          <Card className="group shadow-sm hover:shadow-lg">
            <div className="mb-2 inline-block rounded-lg">
              <Truck className="h-6 w-6 text-blue-600" />
            </div>
            <h3 className="mb-2 text-xl font-semibold">{t('home.fastDelivery')}</h3>
            <p className="text-gray-600 mb-6">{t('home.fastDeliveryDescription')}</p>
          </Card>
        </motion.div>

        <motion.div
          whileHover={{
            scale: 1.03,
          }}
          transition={{
            duration: 0.2,
          }}
        >
          <Card className="group shadow-sm hover:shadow-lg">
            <div className="mb-2 inline-block rounded-lg">
              <CreditCard className="h-6 w-6 text-blue-600" />
            </div>
            <h3 className="mb-2 text-xl font-semibold">
              <p>{t('home.easyPaymentMethod')}</p>
            </h3>
            <p className="text-gray-600 mb-6">{t('home.easyPaymentMethodDescription')}</p>
          </Card>
        </motion.div>

        <motion.div
          whileHover={{
            scale: 1.03,
          }}
          transition={{
            duration: 0.2,
          }}
        >
          <Card className="group shadow-sm hover:shadow-lg">
            <div className="mb-2 inline-block rounded-lg">
              <Gift className="h-6 w-6 text-blue-600" />
            </div>
            <h3 className="mb-2 text-xl font-semibold">{t('home.getOffers')}</h3>
            <p className="text-gray-600 mb-6">{t('home.getOffersDescription')}</p>
          </Card>
        </motion.div>
      </div>
    </div>
  );
};

export default withTranslation()(ComingSoon);
