import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
  name: "user",
  initialState: {
    data: null,
  },
  reducers: {
    setUserData: (user, action) => {
      user.data = action.payload;
    }
  },
});

export const { setUserData } = slice.actions;

export default slice.reducer;
