import React, { useState } from 'react';
import { Card, Button, Radio, Input } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { prevStep } from '../../services/store/slices/cartSlice';
import cogoToast from 'cogo-toast';
import { addOrder } from '../../network/order';
import { useMutation, useQuery } from '@tanstack/react-query';
import queryClient from '../../services/react-query/queryClient';
import { queryKeys } from '../../services/react-query/queryKeys';
import ShippingAddress from './ShippingAddress';
import OrderSummary from './OrderSummary';
import { CreditCardIcon } from 'lucide-react';
import useGetUserData from '../../hooks/useGetUserData';
import { listAddresses } from '../../network/address';
import { useNavigate } from 'react-router';

const Checkout = ({ t, i18n }) => {
  const cart = useSelector((state) => state.cart);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedPayment, setSelectedPayment] = useState('cash');
  const { user } = useGetUserData();
  const { data: addresses } = useQuery({
    queryKey: [queryKeys.listAddresses],
    queryFn: listAddresses,
  });

  const { mutate: onBuyNow, isPending: isBuyPending } = useMutation({
    mutationFn: async (formData) => await addOrder(formData),
    onSuccess: async (data) => {
      if (data.status === "true") {
        if (data.data.payment_url) {
          window.location.href = data.data.payment_url;
        } else {
          cogoToast.success(t("messages.orderPlacedSuccessfully"), {
            position: "top-right",
          });
          queryClient.invalidateQueries([queryKeys.listOrders]);
          navigate('/order/confirmation');
        }
      } else {
        cogoToast.error(t("messages.errorOccurred"), {
          position: "top-right",
        });
      }
    },
    onError: () => {
      cogoToast.error(t("messages.errorOccurred"), {
        position: "top-right",
      });
    },
  });

  const currentAddresses = addresses?.data?.filter(
    (address) => address?.is_current_address === 1
  ) || [];

  const onFinish = async () => {
    const orderData = {
      client_address_id: currentAddresses[0]?.id,
      payment_method: selectedPayment,
      promo_code_id: cart.promoCodeId ? cart.promoCodeId : null,
    };

    onBuyNow(orderData);
  };

  const handleNext = () => {
    onFinish();
  };

  return (
    <div className="flex flex-col lg:flex-row gap-8">
      <div className="flex-1">
        <ShippingAddress t={t} i18n={i18n} user={user} />
        <Card
          title={t("paymentMethod.paymentMethod")}
          className="hover:shadow-md transition-shadow"
        >
          <Radio.Group
            className="w-full"
            value={selectedPayment}
            onChange={(e) => setSelectedPayment(e.target.value)}
          >
            <div className="space-y-4">
              <Radio
                defaultChecked
                value="cash"
                className="w-full p-6 border rounded-lg hover:border-[#1890ff] transition-colors"
              >
                {t("messages.cashPayment")}
                <p className="text-sm text-gray-500 ml-6">
                  {t("messages.cashPaymentDescription")}
                </p>
              </Radio>
              <Radio
                disabled
                value="card"
                className="w-full hidden p-6 border rounded-lg hover:border-[#1890ff] transition-colors"
              >
                <div className="flex items-center">
                  <CreditCardIcon className="w-6 h-6 mx-2" />
                  {t("paymentMethod.creditCard")}
                </div>
                {selectedPayment === "card" && (
                  <div className="ml-8 mt-4 space-y-4">
                    <Input placeholder="Card Number" />
                    <div className="flex gap-4">
                      <Input placeholder="MM/YY" className="flex-1" />
                      <Input placeholder="CVV" className="flex-1" />
                    </div>
                  </div>
                )}
              </Radio>
            </div>
          </Radio.Group>
        </Card>
        <div className="flex justify-between mt-6">
          <Button onClick={() => dispatch(prevStep())}>
            {t('messages.previous')}
          </Button>
        </div>
      </div>

      <div className="lg:w-1/3">
        <OrderSummary t={t} i18n={i18n} user={user} onClick={handleNext} loading={isBuyPending} />
      </div>
    </div>
  );
};

export default Checkout;
