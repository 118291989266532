import React from 'react';
import { useTranslation } from 'react-i18next';
import QueryProvider from './services/react-query';
import { ConfigProvider } from "antd";
import RoutesWrapper from './routes';

const App = () => {
  const { i18n } = useTranslation();
  const currentLang = i18n.language;
  console.log = () => {};
  console.error = () => {};
  console.warn = () => {};
  
  return (
    <QueryProvider>
      <ConfigProvider
        direction={currentLang === "ar" ? "rtl" : "ltr"}
      >
        <RoutesWrapper />
      </ConfigProvider>
    </QueryProvider>
  );
};

export default App;
