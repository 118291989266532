import ForgetPasswordPage from "../pages/Auth/ForgotPasswordPage";
import ResetPasswordPage from "../pages/Auth/ResetPasswordPage";
import LoginPage from "../pages/Auth/LoginPage";
import RegisterPage from "../pages/Auth/RegisterPage";

const routesAuthentication = [
  {
    path: "forgot-password",
    element: <ForgetPasswordPage />,
  },
  {
    path: "login",
    element: <LoginPage />,
  },
  {
    path: "reset-password",
    element: <ResetPasswordPage />,
  },
  {
    path: "register",
    element: <RegisterPage />,
  },
];

export default routesAuthentication;
