import React from "react";
import footerLogo from "../../assets/images/logo.png";
import { Link } from "react-router-dom";
import { withTranslation } from 'react-i18next';
import { FiTwitter, FiFacebook, FiInstagram, FiGithub, FiMessageSquare } from "react-icons/fi";
import { Image, Layout } from "antd";

const Footer = ({ t }) => {
  const { Footer: AntFooter } = Layout;
  const iconStyles = {
    base: "flex items-center justify-center text-gray-500 transition-all duration-200 bg-transparent border border-gray-600 rounded-full w-7 h-7",
    hoverFocus:
      "focus:bg-blue-600 hover:text-white focus:text-white hover:bg-blue-600 hover:border-blue-600 focus:border-blue-600",
  };

  const linkClasses =
    "text-base transition-all duration-200 hover:text-opacity-80 focus:text-opacity-80 cursor-pointer";

  return (
    <AntFooter className="bg-white shadow-inner">
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-10 gap-y-12 gap-x-8 xl:gap-x-12">
          <div className="col-span-2 md:col-span-4 xl:pr-8">
            <Image
              className="w-auto h-9"
              src={footerLogo}
              alt="ShopiSonic Logo"
              preview={false}
              width={170}
              height={40}
            />
            <p className="text-base leading-relaxed text-gray-600 mt-3">
              {t('footer.footerDescription')}
            </p>
            <Link
              to="/contact"
              title="Contact Us"
              className="inline-flex items-center justify-center px-6 py-4 font-semibold text-white transition-all duration-200 bg-blue-600 rounded-md hover:bg-blue-700 hover:text-white focus:bg-blue-700 mt-7"
            >
              <FiMessageSquare className="w-6 h-6" />
              {t('footer.contactUs')}
            </Link>
          </div>

          {[
            {
              title: t('footer.company'),
              links: [
                {
                  name: t('footer.aboutUs'),
                  href: "/",
                },
                {
                  name: t('footer.privacyPolicy'),
                  href: "/privacy-policy",
                },
                {
                  name: t('footer.shipping'),
                  href: "/",
                }
              ]
            },
            {
              title: t('footer.help'),
              links: [
                {
                  name: t('footer.contactUs'),
                  href: "/contact",
                },
                {
                  name: t('profile'),
                  href: "/profile",
                },
                {
                  name: t('footer.returns'),
                  href: "",
                }
              ]
            },
            {
              title: t('footer.extraLinks'),
              links: [
                {
                  name: t('footer.orders'),
                  href: "/orders",
                },
                {
                  name: t('footer.myCart'),
                  href: "/cart",
                },
                {
                  name: t('footer.wishlist'),
                  href: "/favorites",
                }
              ],
            },
          ].map((section, index) => (
            <div className="lg:col-span-2" key={index}>
              <p className="text-base font-semibold text-gray-900">
                {section.title}
              </p>
              <ul className="mt-6 space-y-5">
                {section.links.map((link, idx) => (
                  <li key={idx}>
                    <Link
                      to={link.href}
                      title={link.name}
                      className={`${linkClasses.base} ${linkClasses.hoverFocus}`}
                    >
                      {link.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>

        <hr className="mt-5 mb-4 border-gray-200" />

        <div className="sm:flex sm:items-center sm:justify-between">
          <p className="text-gray-600  text-sm" >
            <Link target="_blank" to="https://techiesonic.com">
              {t('footer.copyright')}
            </Link>
          </p>

          <ul className="flex items-center mt-5 md:order-3 sm:mt-0">
            {[
              {
                icon: <FiTwitter className="w-4 h-4" />,
                href: "https://www.facebook.com/shopisonic",
              },
              {
                icon: <FiFacebook className="w-4 h-4" />,
                href: "https://www.facebook.com/shopisonic",
              },
              {
                icon: <FiInstagram className="w-4 h-4" />,
                href: "https://www.instagram.com/shopi.sonic",
              },
              {
                icon: <FiGithub className="w-4 h-4" />,
                href: "https://github.com",
              },
            ].map((item, index) => (
              <li key={index}>
                <Link
                  to={item.href}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={`mx-2 ${iconStyles.base} ${iconStyles.hoverFocus}`}
                >
                  {item.icon}
                </Link>
              </li>
            ))}
          </ul>
        </div>
    </AntFooter>
  );
}

export default withTranslation()(Footer);
