import Confirmation from "../components/Cart/Confirmation";
import OrderListPage from "../pages/Order";
import PaymentSuccess from "../pages/PaymentSuccess";
import OrderTrackingPage from "../pages/TrakingOrder";

const routesOrders = [
  {
    path: "/orders",
    element: <OrderListPage />,
  },
  {
    path: "/orders/tracking",
    element: <OrderTrackingPage />,
  },
  {
    path: "/payment/success",
    element: <PaymentSuccess />,
  },
  {
    path: "/order/confirmation",
    element: <Confirmation />,
  },
];

export default routesOrders;
