import { React, useState } from "react";
import { Space, Select, Tag, Statistic, Radio, Pagination, Table, Tooltip, Skeleton, Row, Col } from "antd";
import { Link } from "react-router-dom";
import { withTranslation } from 'react-i18next';
import { useMutation, useQuery } from "@tanstack/react-query";
import { queryKeys } from "../../services/react-query/queryKeys";
import { CancelOrder, listOrders } from "../../network/order";
import { Card, Typography } from 'antd';
import { AppstoreOutlined, CheckCircleOutlined, CloseOutlined, DollarOutlined, EyeOutlined, ShoppingOutlined, SyncOutlined, TableOutlined } from "@ant-design/icons";
import cogoToast from "cogo-toast";
import queryClient from "../../services/react-query/queryClient";
import SpinLoading from "../Common/SpinLoading";
import EmptyState from "../Common/Empty";

const { Option } = Select;

const OrderList = ({ t, i18n }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [viewMode, setViewMode] = useState("grid");
  const [filters, setFilters] = useState({ status: '', sort: '-date' });

  const handleCancel = (orderId, status) => {
    if (status !== "pending") {
      cogoToast.warn(t("messages.cannotCancel"), { position: "top-right" });
      return;
    }
    onCancelOrder(orderId);
  };

  const { data: orderData, isLoading } = useQuery({
    queryKey: [queryKeys.listOrders, filters, i18n],
    queryFn: () => listOrders(filters),
    enabled: !!i18n,
  });

  const { mutate: onCancelOrder } = useMutation({
    mutationFn: (orderId) => CancelOrder(orderId),
    onSuccess: () => {
      cogoToast.success(t("messages.orderCanceled"), { position: "top-right" });
      queryClient.invalidateQueries([queryKeys.listOrders, filters, i18n]);
    },
    onError: (error) => {
      cogoToast.error(error.message || t("messages.errorOccurred"), { position: "top-right" });
    },
  });

  const itemsPerPage = 10;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = orderData?.data?.slice(indexOfFirstItem, indexOfLastItem);

  const renderEmptyState = () => (
    <EmptyState text={<div>{t('noOrders')} <Link to="/">{t('addNow')}</Link></div>} />
  );

  const statusColors = {
    pending: "blue",
    processing: "orange",
    onDelivery: "purple",
    completed: "green",
    canceled: "red",
  };

  const getCurrentStatus = (status) => {
    const statusMap = {
      pending: t("orderStatus.pending"),
      processing: t("orderStatus.processing"),
      onDelivery: t("orderStatus.onDelivery"),
      completed: t("orderStatus.completed"),
      canceled: t("orderStatus.canceled"),
    };
    return statusMap[status];
  };

  const handleStatusChange = (value) => {
    setFilters((prev) => ({ ...prev, status: value }));
  };

  const handleSortChange = (value) => {
    setFilters((prev) => ({ ...prev, sort: value }));
  };

  const columns = [
    {
      title: t("order_id"),
      dataIndex: "id",
      sorter: (a, b) => a.id.localeCompare(b.id),
    },
    {
      title: t("orderDate"),
      dataIndex: "date",
      sorter: (a, b) => new Date(a.date) - new Date(b.date),
    },
    {
      title: t("items"),
      render: (order) => order?.items?.length || 0,
      sorter: (a, b) => (a.order?.items?.length || 0) - (b.order?.items?.length || 0),
    },
    {
      title: t("orderTotal"),
      dataIndex: "total_price",
      sorter: (a, b) =>
        parseFloat(a.total_price.replace("SAR", "")) -
        parseFloat(b.total_price.replace("SAR", "")),
    },
    {
      title: t("status"),
      dataIndex: "status",
      render: (status) => <Tag color={statusColors[status]}>{getCurrentStatus(status)}</Tag>,
      filters: Object.keys(statusColors).map((status) => ({
        text: t(`orderStatus.${status}`),
        value: status,
      })),
      onFilter: (value, record) => record.status === value,
    },
    {
      title: t("actions"),
      render: (order) => (
        <Space>
          <Tooltip title={t("view_details")}>
            <Link to="/orders/tracking" state={order}>
              <EyeOutlined style={{ fontSize: "18px" }} className="text-blue-600 hover:text-[#1677ff]" />
            </Link>
          </Tooltip>
          <Tooltip title={t("cancel_order")}>
            <CloseOutlined className="mx-2" style={{
              fontSize: "18px",
              color: order.status === "pending" ? "red" : "gray",
              cursor: order.status === "pending" ? "pointer" : "not-allowed",
            }}
              onClick={() => handleCancel(order.id, order.status)}
            />
          </Tooltip>
        </Space>
      ),
    },
  ];

  const renderStatistics = () => (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-8">
      <Card>
        <Statistic
          title={t("total_orders")}
          value={orderData?.data.length}
          prefix={<ShoppingOutlined />}
        />
      </Card>
      <Card>
        <Statistic
          title={t("pending_orders")}
          value={orderData?.data.filter((o) => o.status === "pending").length}
          prefix={<SyncOutlined spin />}
          valueStyle={{ color: "#1677ff" }}
        />
      </Card>
      <Card>
        <Statistic
          title={t("completed_orders")}
          value={orderData?.data.filter((o) => o.status === "completed").length}
          prefix={<CheckCircleOutlined />}
          valueStyle={{ color: "#52c41a" }}
        />
      </Card>
      <Card>
        <Statistic
          title={t("total_spent")}
          value={orderData?.data
            .filter((order) => order.status === "completed")
            .reduce((acc, curr) => acc + parseFloat(curr.total_price.replace(" SAR", "")), 0)
            .toFixed(2)}
          prefix={<DollarOutlined />}
          suffix={t("currency")}
        />
      </Card>
    </div>
  );

  const renderViewToggle = () => (
    <div className="flex flex-wrap gap-4">
      <Select
        defaultValue=""
        style={{ width: 120 }}
        onChange={handleStatusChange}
      >
        <Option value="">{t("all_status")}</Option>
        {Object.keys(statusColors).map((status) => (
          <Option key={status} value={status}>
            {t(`orderStatus.${status}`)}
          </Option>
        ))}
      </Select>
      <Select onChange={handleSortChange} defaultValue="-date" style={{ width: 120 }}>
        <Option value="-date">{t("newest_first")}</Option>
        <Option value="date">{t("oldest_first")}</Option>
        <Option value="-total_price">{t("highest_amount")}</Option>
        <Option value="total_price">{t("lowest_amount")}</Option>
      </Select>
      <Radio.Group value={viewMode} onChange={(e) => setViewMode(e.target.value)}>
        <Radio.Button value="grid">
          <AppstoreOutlined />
        </Radio.Button>
        <Radio.Button value="table">
          <TableOutlined />
        </Radio.Button>
      </Radio.Group>
    </div>
  );

  const renderContent = () => {
    if (isLoading) {
      return (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {[...Array(6)].map((_, i) => (
            <Card key={i}>
              <Skeleton active />
            </Card>
          ))}
        </div>
      );
    }

    if (orderData?.data.length === 0) {
      return renderEmptyState();
    }

    return viewMode === "grid" ? (
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {currentItems.map((order) => (
          <Card
            className="hover:shadow-lg transition-all duration-300 transform hover:-translate-y-1"
            actions={[
              <Tooltip title={t('view_details')} className="text-center">
                <Link className="custom-links" to="/orders/tracking" state={order}>
                  <EyeOutlined style={{ fontSize: '18px' }} className="text-blue-600 hover:text-[#1677ff]" />
                </Link>
              </Tooltip>,
              <Tooltip title={t("cancel_order")}>
                <CloseOutlined className="mx-2" disabled={order.status !== "pending"} style={{
                  fontSize: "18px",
                  color: order.status === "pending" ? "red" : "gray",
                  cursor: order.status === "pending" ? "pointer" : "not-allowed",
                }}
                  onClick={() => handleCancel(order.id, order.status)}
                />
              </Tooltip>
            ]}
          >
            <div className="flex justify-between items-start mb-4">
              <div>
                <p className="text-xs text-gray-500 mb-1">{t('order_id')}</p>
                <p className="font-medium">{order.id}</p>
              </div>
              <Tag color={statusColors[order.status]}>{getCurrentStatus(order.status)}</Tag>
            </div>
            <div className="grid grid-cols-2 gap-4">
              <div>
                <p className="text-xs text-gray-500 mb-1">{t('orderDate')}</p>
                <p className="font-medium">{order.date}</p>
              </div>
              <div>
                <p className="text-xs text-gray-500 mb-1">{t('items')}</p>
                <p className="font-medium">{order?.items?.length} {t('items')}</p>
              </div>
              <div className="col-span-2">
                <p className="text-xs text-gray-500 mb-1">{t('orderTotal')}</p>
                <p className="font-medium text-lg">{order.total_price}</p>
              </div>
            </div>
          </Card>
        ))}
      </div>
    ) : (
      <Table columns={columns} scroll={{ x: 'max-content' }} dataSource={currentItems} rowKey="id" pagination={false} locale={{ emptyText: <EmptyState description={t('no_data')} /> }} />
    );
  };


  if (isLoading) {
    return <SpinLoading />;
  }

  return (
    <div>
      {renderStatistics()}

      <Row gutter={{ xs: 8, sm: 16, md: 8, lg: 14 }} justify={"space-between"} className='my-3'>
        <Col className='text-[16px] text-normal mb-3' style={{ color: "rgba(0, 0, 0, 0.85)" }}>
          <Typography.Title
            level={4}
            className="mb-5 w-full xxl:w-auto xl:w-auto lg:w-auto md:w-auto sm:w-auto"
          >
            {t('myOrders')}
          </Typography.Title>
        </Col>
        <Col className='mb-3'>
          <Row gutter={{ xs: 8, sm: 16, md: 8, lg: 14 }} justify={"space-between"}>
            {renderViewToggle()}
          </Row>
        </Col>
      </Row>

      {renderContent()}

      <div className="flex justify-center mt-8">
        <Pagination
          current={currentPage}
          total={orderData?.data?.length}
          pageSize={itemsPerPage}
          onChange={setCurrentPage}
        />
      </div>
    </div>
  );
};

export default withTranslation()(OrderList);
