import React from "react";
import { withTranslation } from 'react-i18next';
import RelatedProducts from "./RelatedProducts";
import Product from "./Product";

const ProductDetails = ({ data, t, i18n }) => {
  return (
    <div>
      <Product data={data} t={t} />
      <RelatedProducts data={data} t={t} i18n={i18n} />
    </div>
  );
};

export default withTranslation()(ProductDetails);