import { ChevronLeft, ChevronRight } from "lucide-react";
import React, { useState } from "react";
import { withTranslation } from 'react-i18next';

const ImageGallery = ({ items }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const goToNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === items.length - 1 ? 0 : prevIndex + 1,
    );
  };
  const goToPrevious = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? items.length - 1 : prevIndex - 1,
    );
  };
  return (
    <div className="relative">
      <div className="aspect-w-1 aspect-h-1 w-full">
        <img
          src={items[currentIndex]}
          alt={`Product ${currentIndex + 1}`}
          className="w-full aspect-video object-fit rounded-lg"
          />
      </div>
      <button
        onClick={goToPrevious}
        className="absolute left-2 top-1/2 -translate-y-1/2 p-2 rounded-full bg-white/80 hover:bg-white shadow-sm"
      >
        <ChevronLeft className="w-6 h-6" />
      </button>
      <button
        onClick={goToNext}
        className="absolute right-2 top-1/2 -translate-y-1/2 p-2 rounded-full bg-white/80 hover:bg-white shadow-sm"
      >
        <ChevronRight className="w-6 h-6" />
      </button>
      <div className="mt-4 flex justify-center gap-2">
        {items.map((item, index) => (
          <button
            key={index}
            onClick={() => setCurrentIndex(index)}
            className={`w-16 h-16 rounded-lg overflow-hidden ${currentIndex === index ? "ring-2 ring-blue-500" : ""}`}
          >
            <img
              src={item}
              alt={`Product thumbnail ${index + 1}`}
              className="w-full h-full object-cover"
            />
          </button>
        ))}
      </div>
    </div>
  );
};

export default withTranslation()(ImageGallery);