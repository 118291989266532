import React from "react";
import Category from "../../components/ProductCategory";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { queryKeys } from "../../services/react-query/queryKeys";
import { listCategoryProducts } from "../../network/product";
import { useTranslation } from "react-i18next";
import SpinLoading from "../../components/Common/SpinLoading";

const ProductCategoryPage = () => {
  const { category } = useParams();
  const { i18n } = useTranslation();

  const { data: productData, isLoading } = useQuery({
    queryKey: [queryKeys.listCategoryProducts, category, i18n],
    queryFn: () => listCategoryProducts(category),
    enabled: !!category && !!i18n,
  });

  if (isLoading) {
    return <SpinLoading />;
  }

  return (
    <Category
      Category={category}
      ProductData={productData?.data} />
  );
};

export default ProductCategoryPage;
