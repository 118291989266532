import React from "react";
import { Col, Row, Card, Typography } from "antd";
import { Link } from "react-router-dom";
import { withTranslation } from 'react-i18next';
import { queryKeys } from "../../services/react-query/queryKeys";
import { listCategories } from "../../network/category";
import { useQuery } from "@tanstack/react-query";
import SpinLoading from "../Common/SpinLoading";

const { Meta } = Card;

const getRandomColor = () => {
  const colors = ["#FEEFEA", "#F2FCE4", "#DEF9EC", "#FFF3FF", "#FFFCEB"];
  return colors[Math.floor(Math.random() * colors.length)];
};

const AllCategory = ({ t, i18n }) => {
  const { data, isLoading } = useQuery({
    queryKey: [queryKeys.listCategories, i18n],
    queryFn: listCategories,
    enabled: !!i18n,
  });

  if (isLoading) {
    return <SpinLoading />;
  }

  const MyView = data.data.map((item, i) => (
    <Col xl={4} lg={4} md={4} sm={12} xs={12} className="p-0 my-3" key={i.toString()}>
      <Card
        hoverable
        style={{ backgroundColor: getRandomColor() }}
        cover={
          <Link className="custom-links" to={`/productcategory/${item.id}`} style={{ textDecoration: "none" }}>
            <img alt={item.name} src={item.image} />
          </Link>
        }
      >
        <Meta title={
          <div className="text-center">
            {item.name}
          </div>
        } />
      </Card>
    </Col>
  ));

  return (
    <div >
      <Typography.Title
        level={4}
      >
        {t('AllCategories')}
      </Typography.Title>
      <Row gutter={20} className="mt-5">{MyView}</Row>
    </div>
  );
};

export default withTranslation()(AllCategory);
