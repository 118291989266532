import React, { useState } from 'react';
import { Button, Card, Form, Input, Skeleton } from 'antd';
import { queryKeys } from '../../services/react-query/queryKeys';
import { listAddresses } from '../../network/address';
import { useQuery } from '@tanstack/react-query';
import { EditIcon } from 'lucide-react';
import ModalAddress from '../Addresses/ModalAddress';
import useGetUserData from '../../hooks/useGetUserData';

const ShippingAddress = ({ t, i18n }) => {
  const { user } = useGetUserData();
  const [editAddress, setEditAddress] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editingAddress, setEditingAddress] = useState(null);
  const { data: addresses, isLoading } = useQuery({
    queryKey: [queryKeys.listAddresses, i18n],
    queryFn: listAddresses,
    enabled: !!i18n && !!user
  });

  const openModal = (address = null) => {
    setEditingAddress(address);
    setIsModalVisible(true);
  };

  const closeModal = () => {
    setEditingAddress(null);
    setEditAddress(null);
    setIsModalVisible(false);
  };

  const currentAddresses = addresses?.data?.filter(
    (address) => address?.is_current_address === 1
  ) || [];

  if (isLoading) {
    return (
      <Card className='mb-5'>
        <Skeleton active />
      </Card>
    );
  }

  return (
    <div>
      {currentAddresses.map((address) => (
        <Card
          title={t('messages.shippingAddress')}
          extra={
            <Button
              type="link"
              onClick={() => openModal(address)}
              icon={<EditIcon className="w-4 h-4" />}
            >
              {editAddress ? t('messages.cancel') : t('messages.edit')}
            </Button>
          }
          className="hover:shadow-md transition-shadow mb-5"
        >
          {!editAddress ? (
            <>
              <p className="text-gray-600">{address.street}</p>
              <p className="text-gray-600">{address.city}, {address.country}</p>
            </>
          ) : (
            <Form layout="vertical">
              <Form.Item label="Street Address" required>
                <Input defaultValue="123 Main Street" />
              </Form.Item>
              <Form.Item label="Apartment/Suite">
                <Input defaultValue="Apt 4B" />
              </Form.Item>
              <div className="flex gap-4">
                <Form.Item label="City" required className="flex-1">
                  <Input defaultValue="New York" />
                </Form.Item>
                <Form.Item label="State" required className="flex-1">
                  <Input defaultValue="NY" />
                </Form.Item>
                <Form.Item label="ZIP" required className="flex-1">
                  <Input defaultValue="10001" />
                </Form.Item>
              </div>
              <Button type="primary" className="mr-2">
                Save Changes
              </Button>
            </Form>
          )}
        </Card>
      ))}

      <ModalAddress
        t={t}
        visible={isModalVisible}
        onClose={closeModal}
        editingAddress={editingAddress}
      />
    </div>
  );
};

export default ShippingAddress;
