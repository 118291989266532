import CartPage from "../pages/Cart";

const routesCart = [
  {
    path: "/cart",
    element: <CartPage />,
  },
];

export default routesCart;
