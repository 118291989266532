import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { OrderDetails } from "../../network/order";
import { queryKeys } from "../../services/react-query/queryKeys";
import { useQuery } from "@tanstack/react-query";
import { Card, Steps } from 'antd';
import { CheckCircle, Clock, LucideCircleDot, Package2, Truck } from "lucide-react";
import SpinLoading from "../Common/SpinLoading";

const getCurrentStep = (status) => {
    switch (status) {
        case 'pending':
            return 0;
        case 'processing':
            return 1;
        case 'onDelivery':
            return 2;
        case 'completed':
            return 3;
        default:
            return 0;
    }
};

const OrderTracking = () => {
    const { t, i18n } = useTranslation();
    const location = useLocation();
    const code = location.state.id;

    const { data: orderData, isLoading } = useQuery({
        queryKey: [queryKeys.listOrders, code, i18n],
        queryFn: () => OrderDetails(code),
        enabled: !!i18n,
    });

    if (isLoading) {
        return <SpinLoading />;
    }

    const getCurrentStatus = (status) => {
        switch (status) {
            case 'pending':
                return t('orderStatus.pending');
            case 'processing':
                return t('orderStatus.processing');
            case 'onDelivery':
                return t('orderStatus.onDelivery');
            case 'completed':
                return t('orderStatus.completed');
            case 'canceled':
                return t('orderStatus.canceled');
            default:
                return 0;
        }
    };

    const getCurrentPayment = (payment) => {
        switch (payment) {
            case 'credit_card':
                return t('paymentMethod.credit_card');
            case 'cash':
                return t('paymentMethod.cash');
            default:
                return 0;
        }
    };

    const totalPrice = orderData.data.delivery_charge ? parseInt(orderData.data.total_price) + parseInt(orderData.data.delivery_charge) : parseInt(orderData.data.total_price);

    return (
        <div >
            {orderData.data.status !== 'canceled' && (
                <div className="bg-white p-6 rounded-lg shadow-md mb-8">
                    <Steps
                        current={getCurrentStep(orderData.data.status)}
                        direction="horizontal"
                        items={[
                            {
                                title: t('orderStatus.pending'),
                                icon: <Clock />,
                            },
                            {
                                title: t('orderStatus.processing'),
                                icon: <Package2 />,
                            },
                            {
                                title: t('orderStatus.onDelivery'),
                                icon: <Truck />,
                            },
                            {
                                title: t('orderStatus.completed'),
                                icon: <CheckCircle />,
                            },
                        ]}
                    />
                </div>
            )}

            <Card
                title={<h2 className="text-xl font-semibold">{t('orderSummary')}</h2>}
                className="mb-8 shadow-lg hover:shadow-xl transition-shadow duration-300"
            >
                <div className="grid grid-cols-1 md:grid-cols-3 gap-6 p-4">
                    <div className="p-4 border rounded-lg hover:bg-gray-50 transition-colors duration-200">
                        <div className="text-gray-500 text-sm font-medium mb-2">
                            {t('paymentMethod.paymentMethod')}
                        </div>
                        <div className="text-lg">{getCurrentPayment(orderData.data.payment_method)}</div>
                    </div>
                    <div className="p-4 border rounded-lg hover:bg-gray-50 transition-colors duration-200">
                        <div className="text-gray-500 text-sm font-medium mb-2">
                            {t('orderTime')}
                        </div>
                        <div className="text-lg">{orderData.data.date}</div>
                    </div>
                    <div className="p-4 border rounded-lg hover:bg-gray-50 transition-colors duration-200">
                        <div className="text-gray-500 text-sm font-medium mb-2">
                            {t('status')}
                        </div>
                        <div className="flex items-center gap-2 text-lg">
                            <LucideCircleDot className="text-blue-500" size={20} />
                            <span>{getCurrentStatus(orderData.data.status)}</span>
                        </div>
                    </div>
                    <div className="p-4 border rounded-lg hover:bg-gray-50 transition-colors duration-200">
                        <div className="text-gray-500 text-sm font-medium mb-2">
                            {t('orderTotal')}
                        </div>
                        <div className="text-lg">{orderData.data.total_price} {t('currency')}</div>
                    </div>
                    <div className="p-4 border rounded-lg hover:bg-gray-50 transition-colors duration-200">
                        <div className="text-gray-500 text-sm font-medium mb-2">
                            {t('deliveryCharge')}
                        </div>
                        <div className="text-lg">{orderData.data.delivery_charge ?? 0}{t('currency')}</div>
                    </div>
                    <div className="p-4 border rounded-lg hover:bg-gray-50 transition-colors duration-200">
                        <div className="text-gray-500 text-sm font-medium mb-2">
                            {t('totalPrice')}
                        </div>
                        <div className="text-lg font-semibold">{totalPrice}{t('currency')}</div>
                    </div>
                    <div className="md:col-span-3 p-4 border rounded-lg hover:bg-gray-50 transition-colors duration-200">
                        <div className="text-gray-500 text-sm font-medium mb-2">
                            {t('deliveryAddress')}
                        </div>
                        <p className="text-lg">{orderData?.data?.clientAddress?.city}, {orderData?.data?.clientAddress?.country}</p>
                        <p className="text-lg">{orderData?.data?.clientAddress?.street}</p>
                    </div>
                </div>
            </Card>

            <Card
                title={<h2 className="text-xl font-semibold">   {t('orderContent')}</h2>}
                className="shadow-lg hover:shadow-xl transition-shadow duration-300"
            >
                {orderData.data.items.map((item) => (
                    <Link
                        to={item.itemable_type === "Product"
                            ? "/products/details"
                            : "/offers/details"
                        }
                        state={item?.itemable.id}
                        className="custom-links"
                        style={{ textDecoration: "none" }}
                    >
                        <div className="mb-3 flex flex-col md:flex-row gap-6 p-6 border rounded-lg hover:bg-gray-50 transition-all duration-300">
                            <div className="relative group">
                                <img
                                    alt={item.name} src={item.itemable.image ?? item.itemable.images[0]}
                                    className="w-40 h-40 object-contain rounded-lg shadow-md group-hover:scale-105 transition-transform duration-300"
                                />
                            </div>
                            <div className="flex-1 space-y-4">
                                <h3 className="text-xl font-medium">{item.itemable.name}</h3>
                                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 text-gray-600">
                                    <div>
                                        <div className="font-medium mb-1">{t('totalPrice')}</div>
                                        <div className="text-lg">{item.quantity}</div>
                                    </div>
                                    <div>
                                        <div className="font-medium mb-1">{t('totalPrice')}</div>
                                        <div className="text-lg">{item.itemable.discount_price ?? item.itemable.price}{t('currency')}</div>
                                    </div>
                                    <div>
                                        <div className="font-medium mb-1">{t('totalPrice')}</div>
                                        <div className="text-lg text-blue-600 font-semibold">
                                            {item.quantity * (item.itemable.discount_price ?? item.itemable.price)}{t('currency')}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Link>
                ))}
            </Card>
        </div>
    );
};

export default OrderTracking;
