import React from "react";
import { Col, Row, Card } from "antd";
import { Link } from "react-router-dom";
import { withTranslation } from 'react-i18next';
import { useQuery } from "@tanstack/react-query";
import { queryKeys } from "../../services/react-query/queryKeys";
import { listOffers } from "../../network/offer";
import SpinLoading from "../Common/SpinLoading";

const AllOffer = ({ t, i18n }) => {
  const { data, isLoading } = useQuery({
    queryKey: [queryKeys.listOffers, i18n],
    queryFn: listOffers,
    enabled: !!i18n,
  });

  if (isLoading) {
    return <SpinLoading />;
  }

  const MyView = data?.data.map((item, i) => (
    <Col xl={6} lg={6} md={12} sm={12} xs={12} key={i.toString()}>
      <Link className="custom-links" to="/offers/details" state={item.id}>
        <Card className="text-white" style={{ height: "220px", border: "none" }}>
          <Card.Img src={item.image} alt="Card image" style={{ height: "100%", objectFit: "cover" }} />
          <Card.ImgOverlay>
            <p className="text-4xl">{item.title}</p>
          </Card.ImgOverlay>
        </Card>
      </Link>
    </Col>
  ));

  return (
    <div>
      <p className="text-3xl">{t('packages')}</p>
      <Row>{MyView}</Row>
    </div>
  );
};

export default withTranslation()(AllOffer);
