import dayjs from 'dayjs';
import { fallbackImg } from './constants';

export const arrayFrom = (count = 1) => Array(+count).fill(0);

export function currencyFormat(num = 0) {
  return parseFloat(num)
    .toFixed(2)
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}
export function numberFormat(num = '0') {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export const limitedText = (text = '', lng) => {
  if (!text) return '';
  const strText = text.toString().trim();
  // console.log('limitedText  strText', strText);
  if (!lng || strText.length <= lng) return strText;
  return strText.substring(0, lng) + '...';
};

export const camelToNrm = text => {
  return text.replace(/[A-Z]/g, t => ' ' + t.toLowerCase());
};

export const getPercentage = (num1, num2) => {
  const percent = Math.floor(100 * (num2 / num1));
  if (percent > 100) return 100;
  return percent;
};

export const onErrorImg = ({ target }, fallback) => {
  target.onerror = null;
  target.src = fallback ? fallback : fallbackImg;
};

export const dateFormat = (date, format = 'YYYY-MM-DD') => {
  return dayjs(date).format(format);
};

export const disabledDateBeforeToday = current => {
  // Can not select days before today and today
  return current && current < dayjs().endOf('day');
};

export const formatDescription = (description) => (
  <div>
    {description?.split('\n').map((line, index) => (
      <span key={index} className="mb-2">
        {line}
        <br />
      </span>
    ))}
  </div>
);