import React, { useState } from "react";
import { Badge, Button, Card, Popconfirm } from 'antd';
import { useQuery, useMutation } from "@tanstack/react-query";
import cogoToast from "cogo-toast";
import { listAddresses, removeAddress, setCurrentAddress } from "../../network/address";
import ModalAddress from "./ModalAddress";
import { EditOutlined, DeleteOutlined, PlusOutlined, StarOutlined } from "@ant-design/icons";
import queryClient from "../../services/react-query/queryClient";
import { queryKeys } from "../../services/react-query/queryKeys";
import { withTranslation } from "react-i18next";
import SpinLoading from "../Common/SpinLoading";
import EmptyState from "../Common/Empty";

const Addresses = ({ t }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editingAddress, setEditingAddress] = useState(null);

  const { data: addresses, isLoading } = useQuery({
    queryKey: [queryKeys.listAddresses],
    queryFn: listAddresses,
  });

  const { mutate: removeAddressMutation } = useMutation({
    mutationFn: removeAddress,
    onSuccess: (data) => {
      if (data.status === "true") {
        cogoToast.success(t("messages.addressRemovedSuccessfully"), { position: "top-right" });
        queryClient.invalidateQueries([queryKeys.listAddresses]);
      } else {
        cogoToast.error(t("messages.errorOccurred"), { position: "top-right" });
      }
    },
    onError: () => {
      cogoToast.error(t("messages.errorOccurred"), { position: "top-right" });
    },
  });

  const { mutate: setDefaultAddressMutation, isPending: isDefaultAddressPending } = useMutation({
    mutationFn: (id) => setCurrentAddress(id),
    onSuccess: (data) => {
      if (data.status === "true") {
        cogoToast.success(t("messages.setDefaultAddressSuccessfully"), { position: "top-right" });
        queryClient.invalidateQueries([queryKeys.listAddresses]);
      } else {
        cogoToast.error(t("messages.errorOccurred"), { position: "top-right" });
      }
    },
    onError: () => {
      cogoToast.error(t("messages.errorOccurred"), { position: "top-right" });
    },
  });

  const openModal = (address = null) => {
    setEditingAddress(address);
    setIsModalVisible(true);
  };

  const closeModal = () => {
    setEditingAddress(null);
    setIsModalVisible(false);
  };


  if (addresses?.data?.length === 0) {
    return (
      <div>
        <EmptyState text={
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => openModal()}
            className="bg-blue-600"
          >
            {t("messages.addAddress")}
          </Button>
        } />

        <ModalAddress
          t={t}
          visible={isModalVisible}
          onClose={closeModal}
          editingAddress={editingAddress}
        />
      </div>
    );
  }

  if (isLoading) {
    return <SpinLoading />;
  }

  return (
    <div>
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-semibold">{t("messages.manageAddresses")}</h1>
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => openModal()}
          className="bg-blue-600"
        >
          {t("messages.addAddress")}
        </Button>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {addresses?.data.map((address) => (
          <Card
            key={address.id}
            className="shadow-sm hover:shadow-md transition-shadow"
          >
            <div className="flex justify-between items-start mb-4">
              <div>
                <h3 className="text-lg font-medium">
                  {address.city}, {address.country}
                </h3>
                <p className="text-gray-600">{address.street}</p>
              </div>
              {address.is_current_address === 1 && (
                <Badge color="blue" text={t("default")} className="ml-2" />
              )}
            </div>

            <div className="flex justify-end gap-2">
              <Button
                icon={<EditOutlined />}
                size="small"
                onClick={() => openModal(address)}
              />
              <Popconfirm
                title={t("messages.confirmDelete")}
                onConfirm={() => removeAddressMutation(address.id)}
                okText={t("yes")}
                cancelText={t("no")}
              >
                <Button icon={<DeleteOutlined />} size="small" />
              </Popconfirm>
              {!address.is_current_address && (
                <Button
                  icon={<StarOutlined />}
                  size="small"
                  loading={isDefaultAddressPending}
                  onClick={() => setDefaultAddressMutation(address.id)}
                >
                  {t("messages.setDefaultAddress")}
                </Button>
              )}
            </div>
          </Card>
        ))}
      </div>

      <ModalAddress
        t={t}
        visible={isModalVisible}
        onClose={closeModal}
        editingAddress={editingAddress}
      />
    </div>
  );
};

export default withTranslation()(Addresses);
