import React from "react";
import Category from "../../components/Category";

const CategoriesPage = () => {
  return (
    <Category />
  );
};

export default CategoriesPage;
