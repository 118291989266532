import React from "react";
import { Link } from "react-router-dom";
import { withTranslation } from 'react-i18next';
import { useQuery } from "@tanstack/react-query";
import { queryKeys } from "../../services/react-query/queryKeys";
import { Card, Row, Col, Rate, Flex, Typography } from 'antd';
import { listProducts } from "../../network/product";
import Meta from "antd/es/card/Meta";

const RelatedProducts = ({ data, t, i18n }) => {
  const isRTL = i18n.language === 'ar';
  const { data: productData } = useQuery({
    queryKey: [queryKeys.listProducts, i18n],
    queryFn: () => listProducts(),
    enabled: !!i18n,
  });

  const MyView = productData?.data.filter((item) => item.id !== data.id).slice(0, 4).map((item, i) => {
    return (
      <Col key={i} className={`my-5 flex justify-center ${isRTL ? 'rtl-card' : ''}`}>
        <Card
          hoverable
          className="xl:w-[320px] w-full"
          cover={
            <Link to="/products/details" state={item.id} className="custom-links" style={{ textDecoration: "none" }}>
              <img
                alt={item.name}
                src={item?.images[0]}
                className="w-full px-2 pt-4 h-80 object-contain"
              />
            </Link>
          }
        >
          <Meta
            title={item.name}
            description={
              <div>
                <Rate disabled defaultValue={item.average_rating} />

                <Flex justify="space-between" className="mt-3">
                  <div className="mt-1">
                    {item.discount_price === null ? (
                      <Typography.Text className="text-[#0F63E3] text-lg">{item.price}{t('currency')}</Typography.Text>
                    ) : (
                      <>
                        <Typography.Text className="text-[#0F63E3] text-lg">{item.discount_price}{t('currency')}</Typography.Text>
                        <Typography.Text delete type="secondary" className="mx-2 text-md">{item.price}{t('currency')}</Typography.Text>
                      </>
                    )}
                  </div>
                </Flex>
              </div>
            }
          />
        </Card>
      </Col>
    );
  });

  return (
    <Card hoverable className="mt-12">
      <h2 className="text-2xl font-bold mb-8">{t('relatedProducts')}</h2>
      <Row gutter={16}>
        {MyView}
      </Row>
    </Card>
  );
};

export default withTranslation()(RelatedProducts);