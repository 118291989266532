import React, { useState } from "react";
import { queryKeys } from "../../services/react-query/queryKeys";
import { listCart, removeItem, itemPlus, itemMinus } from "../../network/cart";
import cogoToast from "cogo-toast";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
    Card,
    InputNumber,
    Button,
    Col,
    Row,
} from "antd";
import {
    Trash2,
    MinusCircle,
    PlusCircle,
} from "lucide-react";
import OrderSummary from "./OrderSummary";
import useGetUserData from "../../hooks/useGetUserData";
import SpinLoading from "../Common/SpinLoading";
import { Link } from "react-router-dom";
import EmptyState from "../Common/Empty";

const CartReview = ({ t, i18n }) => {
    const queryClient = useQueryClient();
    const [loadingItemId, setLoadingItemId] = useState(null);
    const { user } = useGetUserData();

    const { data: cartData, isLoading } = useQuery({
        queryKey: [queryKeys.listCart, i18n],
        queryFn: listCart,
        enabled: !!i18n,
    });

    const { mutate: itemPlusHandler, isPending: isIncrementPending } = useMutation({
        mutationFn: (params) => itemPlus(params),
        onMutate: (itemId) => {
            setLoadingItemId(itemId);
        },
        onSuccess: (data) => {
            if (data.status === "true") {
                cogoToast.success(t('messages.itemQuantityIncreased'), {
                    position: "top-right",
                });
                queryClient.invalidateQueries([queryKeys.listCart]);
            } else {
                cogoToast.error(t("messages.errorOccurred"), {
                    position: "top-right",
                });
            }
            setLoadingItemId(null);
        },
        onError: () => {
            cogoToast.error(t("messages.errorOccurred"), {
                position: "top-right",
            });
            setLoadingItemId(null);
        },
    });

    const { mutate: itemMinusHandler, isPending: isDecrementPending } = useMutation({
        mutationFn: (params) => itemMinus(params),
        onMutate: (itemId) => {
            setLoadingItemId(itemId);
        },
        onSuccess: (data) => {
            if (data.status === "true") {
                cogoToast.success(t('messages.itemQuantityDecreased'), {
                    position: "top-right",
                });
                queryClient.invalidateQueries([queryKeys.listCart]);
            } else {
                cogoToast.error(t("messages.errorOccurred"), {
                    position: "top-right",
                });
            }
            setLoadingItemId(null);
        },
        onError: () => {
            cogoToast.error(t("messages.errorOccurred"), {
                position: "top-right",
            });
            setLoadingItemId(null);
        },
    });

    const { mutate: removeItemMutation, isPending: isRemovePending } = useMutation({
        mutationFn: (params) => removeItem(params),
        onSuccess: (data) => {
            if (data.status === "true") {
                cogoToast.success(t('messages.cartItemRemoved'), {
                    position: "top-right",
                });
                queryClient.invalidateQueries([queryKeys.listCart]);
            } else {
                cogoToast.error(t("messages.errorOccurred"), {
                    position: "top-right",
                });
            }
        },
        onError: () => {
            cogoToast.error(t("messages.errorOccurred"), {
                position: "top-right",
            });
        },
    });

    if (isLoading) {
        return <SpinLoading />;
    }

    if (cartData?.data?.length === 0) {
        return (
            <EmptyState text={<div>{t('cartEmpty')} <Link to="/">{t('addNow')}</Link></div>} />
        );
    }

    return (
        <div className="flex flex-col lg:flex-row gap-8">
            <div className="flex-1">
                <Card className="mb-4" title={
                    <div className="flex items-center justify-between">
                        <span>{t('myCart')}</span>
                        <span className="text-sm text-gray-500">{cartData?.data.length}  {t('items')}</span>
                    </div>}>
                    {cartData?.data?.map((CartList, index) => (
                        <Row
                            key={index}
                            className="items-center gap-4 p-4 border-b hover:bg-gray-50 transition-colors"
                        >
                            <Col xl={5}>
                                <img
                                    src={CartList?.items?.image ?? CartList?.items?.images[0]}
                                    alt={CartList?.items?.name}
                                    className="w-[120px] h-35 object-cover rounded-md"
                                />
                            </Col>
                            <Col xl={16}>
                                <div className="flex justify-between items-start mb-2">
                                    <div>
                                        <h3 className="text-lg font-semibold">{CartList?.items?.name}</h3>
                                    </div>
                                    <Button
                                        danger
                                        icon={<Trash2 className="w-4 h-4" />}
                                        type="text"
                                        onClick={() => removeItemMutation(CartList.id)}
                                        loading={isRemovePending}
                                    >
                                        <div className="hidden sm:block">{t('messages.remove')}</div>
                                    </Button>
                                </div>
                                <div className="flex items-center gap-2 mt-1">
                                    {CartList.items?.discount_price === null ?
                                        <div className="flex items-baseline gap-4">
                                            <span className="font-medium font-bold text-blue-600">
                                                {CartList.items?.price}{t('currency')}
                                            </span>
                                        </div>
                                        :
                                        <div className="flex items-baseline gap-4">
                                            <span className="font-medium font-bold text-blue-600">{CartList.items?.discount_price}{t('currency')}</span>
                                            <span className="text-gray-500 line-through">{CartList.items?.price}{t('currency')}</span>
                                            <span className="bg-red-100 text-red-800 text-sm font-medium px-2.5 py-0.5 rounded">
                                                {Math.round(((CartList.items?.price - CartList.items?.discount_price) / CartList.items?.price) * 100)}% {t('discount')}
                                            </span>
                                        </div>
                                    }
                                </div>
                                <Row className="items-center mt-4" gutter={12} justify={"space-between"}>
                                    <Col className="items-center border rounded-md p-1">
                                        <Button
                                            type="text"
                                            icon={<MinusCircle className="w-4 h-4" />}
                                            disabled={CartList.quantity === "1"}
                                            onClick={() => itemMinusHandler(CartList.id)}
                                            loading={isDecrementPending && loadingItemId === CartList.id}
                                        />
                                        <InputNumber
                                            min={1}
                                            value={CartList.quantity}
                                            className="w-16 border-none"
                                            controls={false}
                                        />
                                        <Button
                                            type="text"
                                            icon={<PlusCircle className="w-4 h-4" />}
                                            onClick={() => itemPlusHandler(CartList.id)}
                                            loading={isIncrementPending && loadingItemId === CartList.id}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    ))}
                </Card>
            </div>

            <div className="lg:w-1/3">
                <OrderSummary t={t} i18n={i18n} user={user} />
            </div>
        </div>
    );
};

export default CartReview;
