import React from "react";
import ProductDetails from "../../components/ProductDetails";
import { useLocation } from "react-router-dom";
import { queryKeys } from "../../services/react-query/queryKeys";
import { productDetails } from "../../network/product";
import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import SpinLoading from "../../components/Common/SpinLoading";

const ProductDetailsPage = () => {
  const { i18n } = useTranslation();
  const location = useLocation();
  const code = location.state;

  const { data: productData, isLoading } = useQuery({
    queryKey: [queryKeys.listProducts, code, i18n],
    queryFn: () => productDetails(code),
    enabled: !!i18n && !!code,
  });

  if (isLoading) {
    return <SpinLoading />;
  }

  return (
    <ProductDetails data={productData?.data} />
  );
};

export default ProductDetailsPage;