import React from 'react';
import { Flex, Empty, Typography } from 'antd';

const EmptyState = ({ text }) => {
  return (
    <Flex className="my-40" align="center" gap="middle" justify="center" >
      <Empty description={
        <div>
          <Typography.Text>
            {text}
          </Typography.Text>
        </div>
      }
      />
    </Flex>
  );
};

export default EmptyState;
