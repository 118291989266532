import PopularProductPage from "../pages/PopularProduct";

const routesPopularProduct = [
  {
    path: "/products/popular",
    element: <PopularProductPage />,
  },
];

export default routesPopularProduct;
