import React from "react";
import ResetPassword from "../../components/Auth/ResetPassword";

const ResetPasswordPage = () => {
  return (
    <ResetPassword />
  );
};

export default ResetPasswordPage;
