import React from "react";
import Contact from "../../components/Contact";

const ContactPage = () => {
  return (
    <Contact />
  );
};

export default ContactPage;
