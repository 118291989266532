import React, { useState } from "react";
import { queryKeys } from "../../services/react-query/queryKeys";
import { listCart } from "../../network/cart";
import cogoToast from "cogo-toast";
import { nextStep, setCart, setPromoCodeId } from '../../services/store/slices/cartSlice';
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  Card,
  Input,
  Button,
  Divider,
  Collapse,
} from "antd";
import { validPromo } from "../../network/promo";
import { useDispatch, useSelector } from "react-redux";
import ModalAddress from "../Addresses/ModalAddress";
import useGetUserData from "../../hooks/useGetUserData";
import { listAddresses } from "../../network/address";

const OrderSummary = ({ t, i18n, onClick, loading }) => {
  const { user } = useGetUserData();
  const [promoCode, setPromoCode] = useState("");
  const [promoCodeData, setPromoCodeData] = useState(null);
  const [promoCodeDiscount, setPromoCodeDiscount] = useState(0);
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const currentStep = useSelector((state) => state.cart.currentStep);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const { data: addresses } = useQuery({
    queryKey: [queryKeys.listAddresses],
    queryFn: listAddresses,
  });

  const openModal = (address = null) => {
    setIsModalVisible(true);
  };

  const closeModal = () => {
    setIsModalVisible(false);
  };

  const { data: cartData } = useQuery({
    queryKey: [queryKeys.listCart, i18n],
    queryFn: listCart,
    enabled: !!i18n && !!user
  });

  const { mutate: applyPromoCode, isPending: isApplying } = useMutation({
    mutationFn: async ({ code }) => validPromo(code),
    onSuccess: (data) => {
      if (data.status === "true") {
        cogoToast.success(t("messages.promoCodeApplied"), {
          position: "top-right",
        });
        setPromoCode(data.data.code);
        setPromoCodeData(data);
        setPromoCodeDiscount(parseFloat(data.data.value));
        queryClient.invalidateQueries([queryKeys.listCart]);
      } else {
        cogoToast.error(t("messages.inValidpromoCode"), {
          position: "top-right",
        });
      }
    },
    onError: (error) => {
      cogoToast.warn(t("messages.inValidpromoCode"), {
        position: "top-right",
      });
    },
  });

  const handleApplyPromo = () => {
    if (promoCode.trim()) {
      applyPromoCode({ code: promoCode });
    } else {
      cogoToast.warn(t("messages.enterPromoCode"), {
        position: "top-right",
      });
    }
  };
  
  const handleNext = () => {
    if (addresses.data.length === 0) {
      cogoToast.warn(t("messages.mustAddAddress"), {
        position: "top-right",
      });
      openModal();
      return;
    }

    dispatch(nextStep());
    dispatch(setCart(total));
    dispatch(setPromoCodeId(promoCodeData?.data?.id));
  };

  const calculateSubtotal = (cartItems) => {
    return cartItems.reduce((total, item) => {
      const price = item.items?.discount_price ?? item.items?.price;
      return total + price * item.quantity;
    }, 0);
  };

  const calculateTotal = (subtotal, shipping, discount = 0) => {
    return subtotal + shipping - discount;
  };

  const subtotal = calculateSubtotal(cartData?.data || []);
  const shipping = 0;
  const total = calculateTotal(subtotal, shipping, promoCodeDiscount);

  const handleAddressAdded = () => {
    cogoToast.success(t("messages.addressAddedSuccessfully"), {
      position: "top-right",
    });
    dispatch(nextStep());
    dispatch(setCart(total));
    dispatch(setPromoCodeId(promoCodeData?.data?.id));
    closeModal();
  };
  return (
    <div>
      <Card title={t("messages.orderSummary")} className="sticky top-4">
        <div className="space-y-4">
          <Collapse
            items={[
              {
                key: "1",
                label: t("messages.applyPromoCode"),
                children: (
                  <div className="flex gap-2">
                    <Input
                      placeholder={t("messages.enterPromoPlaceholder")}
                      value={promoCode}
                      onChange={(e) => setPromoCode(e.target.value)}
                      className="hover:border-[#1677ff]"
                      aria-label="Promo Code Input"
                    />
                    <Button
                      type="primary"
                      onClick={handleApplyPromo}
                      loading={isApplying}
                      disabled={!promoCode.trim()}
                      className="text-white"
                      aria-label="Apply Promo Code"
                    >
                      {t("messages.apply")}
                    </Button>
                  </div>
                ),
              },
            ]}
          />
          <Divider />

          <div className="space-y-2">
            <div className="flex justify-between">
              <span>{t("messages.subtotal")}</span>
              <span>{subtotal.toFixed(2)} {t("currency")}</span>
            </div>
            <div className="flex justify-between">
              <span>{t("messages.shipping")}</span>
              <span>{shipping.toFixed(2)} {t("currency")}</span>
            </div>
            {promoCodeDiscount > 0 && (
              <div className="flex justify-between text-green-500">
                <span>{t("messages.promoDiscount")}</span>
                <span>-{promoCodeDiscount.toFixed(2)} {t("currency")}</span>
              </div>
            )}
            <Divider />
            <div className="flex justify-between text-lg font-medium">
              <span>{t("messages.total")}</span>
              <span className="text-[#1677ff]">{total.toFixed(2)} {t("currency")}</span>
            </div>
          </div>

          <Button type="primary" onClick={currentStep === 1 ? onClick : handleNext} loading={currentStep === 1 ? loading : false} className="w-full mt-5">
            {currentStep === 1 ? t('messages.completeOrder') : t('proceed_checkout')}
          </Button>
        </div>
      </Card>

      <ModalAddress
        t={t}
        visible={isModalVisible}
        onClose={closeModal}
        onAddressAdded={handleAddressAdded}
      />
    </div>
  );
};

export default OrderSummary;
