import React from "react";
import Login from "../../components/Auth/Login";

const LoginPage = () => {
  return (
    <Login />
  );
};

export default LoginPage;
