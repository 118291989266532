import React, { useState } from "react";
import cogoToast from "cogo-toast";
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { queryKeys } from "../../services/react-query/queryKeys";
import { addCart } from "../../network/cart";
import { Card, Button, Flex, Typography, Rate, Col, Row } from 'antd';
import { ShoppingCartOutlined } from '@ant-design/icons';
import { Link } from "react-router-dom";
import useGetUserData from "../../hooks/useGetUserData";
import EmptyState from "../Common/Empty";

const { Meta } = Card;
const SearchList = ({ ProductData }) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const [currentProductId, setCurrentProductId] = useState(null);
  const { user } = useGetUserData();

  const { mutate: onAddCart, isPending: isCartPending } = useMutation({
    mutationFn: (formData) => addCart(formData),
    onSuccess: (data) => {
      if (data.status === "true") {
        cogoToast.success(t("messages.itemAddedSuccessfully"), { position: "top-right" });
        queryClient.invalidateQueries([queryKeys.listCart]);
      } else {
        cogoToast.warn(t("messages.warningOccurred"), { position: "top-right" });
      }
      setCurrentProductId(null);
    },
    onError: () => {
      cogoToast.warn(t("messages.warningOccurred"), { position: "top-right" });
      setCurrentProductId(null);
    }
  });

  const addToCart = (productId) => {
    if (!localStorage.getItem("token")) {
      cogoToast.warn(t("messages.addItemsLoginRequired"), {
        position: "top-right",
      });
    } else {
      setCurrentProductId(productId);
      const formData = new FormData();
      formData.append("quantity", 1);
      formData.append("item_id", productId);
      formData.append("user_id", user.id);
      formData.append("is_product", 1);
      onAddCart(formData);
    }
  };

  if (ProductData?.length === 0) {
    return (
      <EmptyState text={<div>{t('noResultSearch')}</div>} />
    );
  }

  const MyView = ProductData?.map((item, i) => (
    <Col xl={6} lg={4} md={8} sm={12} xs={24} key={i.toString()}>
      <Card
        hoverable
        className="mb-5 xl:w-[270px] 2xl:w-full"
        cover={
          <Link to="/products/details" state={item.id} className="custom-links" style={{ textDecoration: "none" }}>
            <img
              alt="example"
              src={item.images[0]}
              className="w-full"
            />
          </Link>
        }
      >
        <Meta title={item.name} description={
          <div>
            <Rate disabled defaultValue={item.average_rating} />

            <Flex justify="space-between" className="mt-3">
              <div>
                {item.discount_price === null ? (
                  <Typography.Text className="text-[#0F63E3]">{item.price}{t('currency')}</Typography.Text>
                ) : (
                  <>
                    <Typography.Text className="text-[#0F63E3]">{item.discount_price}{t('currency')}</Typography.Text>
                    <Typography.Text delete type="secondary" className="mx-2">{item.price}{t('currency')}</Typography.Text>
                  </>
                )}
              </div>

              <Button type="primary" onClick={() => addToCart(item.id)}
                loading={isCartPending && currentProductId === item.id}
                icon={<ShoppingCartOutlined />} size={"lg"} />
            </Flex>
          </div>
        } />

      </Card>
    </Col>
  ));

  return (
    <div>
      <Typography.Title
        level={4}
      >
        {t('search')}
      </Typography.Title>

      <Row gutter={10} className="mt-5">{MyView}</Row>
    </div>
  );
};

export default SearchList;
