import React from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import Search from "../../components/Search";
import { productSearch } from "../../network/productSearch";
import { queryKeys } from "../../services/react-query/queryKeys";
import { useTranslation } from "react-i18next";
import SpinLoading from "../../components/Common/SpinLoading";

const SearchPage = () => {
  const { searchkey } = useParams();
  const { i18n } = useTranslation();

  const { data, isLoading } = useQuery({
    queryKey: [queryKeys.listProducts, searchkey, i18n],
    queryFn: () => productSearch(searchkey),
    enabled: !!searchkey && !!i18n,
  });

  if (isLoading) {
    return <SpinLoading />;
  }

  return (
    <Search SearchKey={searchkey} ProductData={data?.data} />
  );
};

export default SearchPage;
