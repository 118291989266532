import React from 'react';
import { createRoot } from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from "react-redux";
import persistStore from "redux-persist/es/persistStore";
import store from "./services/store/configureStore";
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import "./styles/globals.scss";
import './i18n';

const persistor = persistStore(store);
const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <App />
        </PersistGate>
      </Provider>
    </BrowserRouter>
  </React.StrictMode>
);

reportWebVitals();
