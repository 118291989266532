import FavoritePage from "../pages/Favorite";

const routesFavorite = [
  {
    path: "/favorites",
    element: <FavoritePage />,
  }
];

export default routesFavorite;
