import {
  Package,
  Truck,
  CreditCard,
  Gift,
  DollarSign,
  Percent,
} from "lucide-react";
import React from "react";
import { Button, Card } from "antd";
import { motion } from "framer-motion";
import { t } from "i18next";
import { useNavigate } from "react-router-dom";

const Banner = () => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate('/coming-soon');
  };

  return (
    <section className="relative w-full overflow-hidden bg-gradient-to-b from-gray-50 to-white">
      <div className="absolute inset-0 opacity-10">
        <motion.div
          animate={{
            y: [0, -20, 0],
          }}
          transition={{
            duration: 4,
            repeat: Infinity,
          }}
        >
          <DollarSign className="absolute top-20 left-40 h-12 w-12" />
        </motion.div>
        <motion.div
          animate={{
            y: [0, -20, 0],
          }}
          transition={{
            duration: 4,
            repeat: Infinity,
            delay: 1,
          }}
        >
          <Percent className="absolute bottom-40 right-20 h-12 w-12" />
        </motion.div>
      </div>

      <div className="xxl:p-12 xl:p-12 lg:p-12 md:p-6 sm:p-6 xs:p-6 p-6">
        <div className="grid items-center gap-12 lg:grid-cols-2">
          <div className="space-y-8">
            <div className="px-4">
              <div className="mb-4 xl:text-4xl md:text-4xl lg:text-4xl md:text-4xl text-xl font-bold tracking-tight text-gray-900">
                {t('home.shopiSonicApp')}
              </div>
              <p className="mb-6 text-lg text-gray-600">
                {t('home.availableApp')}
              </p>
            </div>
            <div className="flex space-x-1">
              <Button onClick={handleClick} className="border-none">
                <motion.img
                  whileHover={{
                    scale: 1.1,
                  }}
                  transition={{
                    duration: 0.2,
                  }}
                  src="https://upload.wikimedia.org/wikipedia/commons/3/3c/Download_on_the_App_Store_Badge.svg"
                  alt="Play Store"
                  className="h-12 xl:w-[150px] lg:w-[150px] md:w-[150px] xxl:w-[150px] w-[120px] cursor-pointer"
                />
              </Button>
              <Button onClick={handleClick} className="border-none">
                <motion.img
                  whileHover={{
                    scale: 1.1,
                  }}
                  transition={{
                    duration: 0.2,
                  }}
                  src="https://upload.wikimedia.org/wikipedia/commons/7/78/Google_Play_Store_badge_EN.svg"
                  alt="Play Store"
                  className="h-12 xl:w-[150px] lg:w-[150px] md:w-[150px] xxl:w-[150px] w-[120px] cursor-pointer"
                />
              </Button>
            </div>
          </div>
          <div className="grid gap-6 sm:grid-cols-2">
            <motion.div
              whileHover={{
                scale: 1.03,
              }}
              transition={{
                duration: 0.2,
              }}
            >
              <Card className="group h-full shadow-sm hover:shadow-lg">
                <div className="mb-2 inline-block rounded-lg">
                  <Package className="h-6 w-6 text-blue-600" />
                </div>
                <h3 className="mb-2 text-xl font-semibold">
                  <p>{t('home.qualityProducts')}</p>
                </h3>
                <p className="text-gray-600">
                  {t('home.qualityProductsDescription')}
                </p>
              </Card>
            </motion.div>

            <motion.div
              whileHover={{
                scale: 1.03,
              }}
              transition={{
                duration: 0.2,
              }}
            >
              <Card className="group shadow-sm hover:shadow-lg">
                <div className="mb-2 inline-block rounded-lg">
                  <Truck className="h-6 w-6 text-blue-600" />
                </div>
                <h3 className="mb-2 text-xl font-semibold">{t('home.fastDelivery')}</h3>
                <p className="text-gray-600">{t('home.fastDeliveryDescription')}</p>
              </Card>
            </motion.div>

            <motion.div
              whileHover={{
                scale: 1.03,
              }}
              transition={{
                duration: 0.2,
              }}
            >
              <Card className="group shadow-sm hover:shadow-lg">
                <div className="mb-2 inline-block rounded-lg">
                  <CreditCard className="h-6 w-6 text-blue-600" />
                </div>
                <h3 className="mb-2 text-xl font-semibold">
                  <p>{t('home.easyPaymentMethod')}</p>
                </h3>
                <p className="text-gray-600">{t('home.easyPaymentMethodDescription')}</p>
              </Card>
            </motion.div>

            <motion.div
              whileHover={{
                scale: 1.03,
              }}
              transition={{
                duration: 0.2,
              }}
            >
              <Card className="group shadow-sm hover:shadow-lg">
                <div className="mb-2 inline-block rounded-lg">
                  <Gift className="h-6 w-6 text-blue-600" />
                </div>
                <h3 className="mb-2 text-xl font-semibold">{t('home.getOffers')}</h3>
                <p className="text-gray-600">{t('home.getOffersDescription')}</p>
              </Card>
            </motion.div>
          </div>

        </div>
      </div>
    </section>
  );
};

export default Banner;
