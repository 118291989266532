import { Outlet, useLocation } from "react-router-dom";
import { Layout } from "antd";
import Header from "./Header";
import Footer from "./Footer";

const { Content } = Layout;

function Main() {
  const location = useLocation();
  const isHomePage = location.pathname === "/";

  return (
    <Layout>
      <Header />
      <Content className={`content-ant ${!isHomePage ? "p-6 xxl:p-12 xl:p-12 lg:p-12 md:p-6 sm:p-6 xs:p-6" : "bg-white"}`}>
        <Outlet />
      </Content>
      <Footer />
    </Layout>
  );
}

export default Main;
