import AccountSettingPage from "../pages/AccountSetting";
import AddressesPage from "../pages/Addresses";
import ProfilePage from "../pages/UserProfile";

const routesSettings = [
  {
    path: "/account/manage",
    element: <AccountSettingPage />,
  },
  {
    path: "/profile",
    element: <ProfilePage />,
  },
  {
    path: "/addresses",
    element: <AddressesPage />,
  },
];

export default routesSettings;