import React from "react";
import ForgotPassword from "../../components/Auth/ForgotPassword";

const ForgetPasswordPage = () => {
  return (
    <ForgotPassword />
  );
};

export default ForgetPasswordPage;
