import React from "react";
import { Button, Form, Input, Flex, Card } from "antd";
import { useTranslation } from 'react-i18next';
import { contactUs } from "../../network/contact";
import 'react-toastify/dist/ReactToastify.css';
import { useMutation } from "@tanstack/react-query";
import cogoToast from "cogo-toast";
import { Mail, MapPin, Phone } from "lucide-react";
import PhoneInputWithCountrySelect, { isValidPhoneNumber } from "react-phone-number-input";
import { motion } from "framer-motion";

const Contact = () => {
  const [form] = Form.useForm();
  const { t } = useTranslation();

  const { mutate: onContact, isPending } = useMutation({
    mutationFn: (formData) => contactUs(formData),
    onSuccess: (data) => {
      form.resetFields();
      cogoToast.success(t("messages.messageSentSuccessfully"), { position: "top-right" });
    },
    onError: () => {
      cogoToast.warn(t("messages.warningOccurred"), { position: "top-right" });
    }
  });

  const onFinish = (values) => {
    onContact(values);
  };

  return (
    <div className="mx-auto max-w-4xl">
      <div className="text-center mb-16">
        <h1 className="text-4xl font-bold text-[#1E293B] mb-4">{t('footer.contactUs')}</h1>
        <p className="text-[#1E293B] text-lg">
          {t('footer.contactUsDescription')}
        </p>
      </div>

      <div className="grid gap-6 sm:grid-cols-3 text-center">
        <motion.div
          whileHover={{
            scale: 1.03,
          }}
          transition={{
            duration: 0.2,
          }}
        >
          <Card className="group h-full shadow-sm hover:shadow-lg">
            <div className="mb-2 inline-block rounded-lg">
              <Phone className="w-12 h-12 text-gray-600" />
            </div>
            <h3 className="mb-2 text-xl font-semibold">
              <p>+201004753538</p>
            </h3>
          </Card>
        </motion.div>

        <motion.div
          whileHover={{
            scale: 1.03,
          }}
          transition={{
            duration: 0.2,
          }}
        >
          <Card className="group shadow-sm hover:shadow-lg">
            <div className="mb-2 inline-block rounded-lg">
              <Mail className="w-12 h-12 text-gray-600" />
            </div>
            <h3 className="mb-2 text-xl font-semibold">
              <p>support@shopisonic.com</p>
            </h3>
          </Card>
        </motion.div>

        <motion.div
          whileHover={{
            scale: 1.03,
          }}
          transition={{
            duration: 0.2,
          }}
        >
          <Card className="group shadow-sm hover:shadow-lg">
            <div className="mb-2 inline-block rounded-lg">
              <MapPin className="w-12 h-12 text-gray-600" />
            </div>
            <h3 className="mb-2 text-xl font-semibold">
              Cairo, Egypt
            </h3>
          </Card>
        </motion.div>
      </div>

      <Flex justify="center" className="my-10">
        <Card className="w-full">
          <h2 className="text-2xl font-bold text-[#1E293B] text-center mb-8">
            {t('footer.contactUsH')}
          </h2>

          <Form form={form} name="contact" onFinish={onFinish} layout="vertical">
            <Form.Item
              name="name"
              label={t('auth.name')}
              required={false}
              rules={[
                { required: true, message: t('auth.nameValidationRequired'), whitespace: true },
              ]}
            >
              <Input className="w-full" placeholder={t("auth.namePlaceholder")} />
            </Form.Item>

            <Form.Item
              name="email"
              label={t('auth.email')}
              required={false}
              rules={[
                { type: 'email', message: t("auth.emailValidation") },
                { required: true, message: t("auth.emailValidationRequired") },
              ]}
            >
              <Input className="w-full" placeholder={t("auth.emailPlaceholder")} />
            </Form.Item>

            <Form.Item
              name={"phone"}
              label={t('auth.phone')}
              colon={false}
              required={false}
              hasFeedback
              rules={[
                { required: true, message: t('auth.phoneValidationRequired') },
                {
                  validator: (_, value) => {
                    if (value && !isValidPhoneNumber(value)) {
                      return Promise.reject(new Error(t('auth.phoneValidation')));
                    } else {
                      return Promise.resolve();
                    }
                  },
                },
              ]}
            >
              <PhoneInputWithCountrySelect
                defaultCountry="EG"
                international
              />
            </Form.Item>
            <Form.Item
              name="message"
              label={t('message')}
              required={false}
              rules={[
                {
                  required: true,
                  message: t('messageValidation'),
                },
              ]}
            >
              <Input.TextArea showCount maxLength={100} rows={3} placeholder={t("auth.messagePlaceholder")} />
            </Form.Item>
            <Form.Item>
              <Button type="primary" loading={isPending} htmlType="submit" className="w-full">
                {t('send')}
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </Flex>
    </div>
  );
};

export default Contact;
