import React, { useState } from "react";
import { Col, Input, Pagination, Row, Select } from "antd";
import { withTranslation } from 'react-i18next';
import cogoToast from "cogo-toast";
import { queryKeys } from "../../services/react-query/queryKeys";
import { listProducts } from "../../network/product";
import { useMutation, useQuery } from "@tanstack/react-query";
import { addCart } from "../../network/cart";
import queryClient from "../../services/react-query/queryClient";
import { Card, Button, Flex, Typography, Rate } from 'antd';
import { ShoppingCartOutlined } from '@ant-design/icons';
import { Link } from "react-router-dom";
import useGetUserData from "../../hooks/useGetUserData";
import SpinLoading from "../Common/SpinLoading";
import EmptyState from "../Common/Empty";

const { Meta } = Card;
const { Option } = Select;

const AllProduct = ({ t, i18n }) => {
  const { user } = useGetUserData();
  const [currentProductId, setCurrentProductId] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortOption, setSortOption] = useState('-price');

  const filters = {
    name: searchTerm,
    sort: sortOption
  };

  const { data: productData, isLoading } = useQuery({
    queryKey: [queryKeys.listProducts, i18n, filters],
    queryFn: () => listProducts(filters),
    enabled: !!i18n,
  });

  const itemsPerPage = 10;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = productData?.data?.slice(indexOfFirstItem, indexOfLastItem);

  const { mutate: onAddCart, isPending: isCartPending } = useMutation({
    mutationFn: (formData) => addCart(formData),
    onSuccess: (data) => {
      if (data.status === "true") {
        cogoToast.success(t("messages.itemAddedSuccessfully"), { position: "top-right" });
        queryClient.invalidateQueries([queryKeys.listCart]);
      } else {
        cogoToast.warn(t("messages.warningOccurred"), { position: "top-right" });
      }
      setCurrentProductId(null);
    },
    onError: () => {
      cogoToast.warn(t("messages.warningOccurred"), { position: "top-right" });
      setCurrentProductId(null);
    }
  });

  const addToCart = (productId) => {
    if (!localStorage.getItem("token")) {
      cogoToast.warn(t("messages.addItemsLoginRequired"), {
        position: "top-right",
      });
    } else {
      setCurrentProductId(productId);
      const formData = new FormData();
      formData.append("quantity", 1);
      formData.append("item_id", productId);
      formData.append("user_id", user?.id);
      formData.append("is_product", 1);
      onAddCart(formData);
    }
  };

  if (isLoading) {
    return <SpinLoading />;
  }

  const productView = currentItems?.map((item, i) => (
    <Col xl={6} lg={6} md={8} sm={12} xs={24} key={i.toString()}>
      <Card
        hoverable
        className="mb-5"
        cover={
          <Link to="/products/details" state={item.id} className="custom-links" style={{ textDecoration: "none" }}>
            <img
              alt={item.name}
              src={item.images[0]}
              className="w-full px-2 pt-4 h-80 object-contain"
            />
          </Link>
        }
      >
        <Meta title={item.name} description={
          <div>
            <Rate disabled defaultValue={item.average_rating} />
            <Flex justify="space-between" className="mt-3">
              <div className="mt-1">
                {item.discount_price === null ? (
                  <Typography.Text className="text-[#0F63E3] text-lg">{item.price}{t('currency')}</Typography.Text>
                ) : (
                  <>
                    <Typography.Text className="text-[#0F63E3] text-lg">{item.discount_price}{t('currency')}</Typography.Text>
                    <Typography.Text delete type="secondary" className="mx-2 text-md">{item.price}{t('currency')}</Typography.Text>
                  </>
                )}
              </div>

              <Button type="primary" onClick={() => addToCart(item.id)}
                loading={isCartPending && currentProductId === item.id}
                icon={<ShoppingCartOutlined />} size={"lg"} />
            </Flex>
          </div>
        } />
      </Card>
    </Col>
  ));

  return (
    <div>
      <Row gutter={{ xs: 8, sm: 16, md: 8, lg: 14 }} justify={"space-between"} className='my-3'>
        <Col className='text-[16px] text-normal mb-3' style={{ color: "rgba(0, 0, 0, 0.85)" }}>
          <Typography.Title
            level={4}
            className="w-full"
          >
            {t('home.products')}
          </Typography.Title>
        </Col>
        <Col className='mb-3'>
          <Row gutter={{ xs: 8, sm: 16, md: 8, lg: 14 }} justify={"space-between"}>
            <Col className='mb-3'>
              <Input.Search
                onSearch={(value) => setSearchTerm(value)}
                placeholder={t("searchPlaceholder")}
                className="w-full md:w-96"
                size="large"
              />
            </Col>

            <Col className='mb-3'>
              <Select onChange={(value) => setSortOption(value)} defaultValue={t("lowest_price")}
                className="w-full md:w-48"
                size="large"
              >
                <Option value="-price">{t("highest_price")}</Option>
                <Option value="price">{t("lowest_price")}</Option>
                <Option value="-ratings_avg_rate">{t("highest_rate")}</Option>
                <Option value="ratings_avg_rate">{t("lowest_rate")}</Option>
              </Select>
            </Col>
          </Row>
        </Col>
      </Row>

      {productData?.data.length === 0 ? (
        <EmptyState text={<div>{t('noProducts')}</div>} />
      ) : (
        <Row gutter={20} className="mt-5">
          {productView}
        </Row>
      )}

      <div className="flex justify-center mt-8">
        <Pagination
          current={currentPage}
          total={productData?.data?.length}
          pageSize={itemsPerPage}
          onChange={setCurrentPage}
        />
      </div>
    </div>
  );
};

export default withTranslation()(AllProduct);
