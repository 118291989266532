import React, { useEffect } from 'react';
import Products from '../../components/Home/Product';
import Hero from '../../components/Home/Hero';
import Banner from '../../components/Home/Banner/Banner';
import { getVisitor } from '../../network/visitor';
import Membership from '../../components/Home/Membership';
import Benefits from '../../components/Home/Benefits';

const HomePage = () => {
  useEffect(() => {
    const fetchVisitorDetails = async () => {
      try {
        await getVisitor();
      } catch (error) {
        console.error('Error fetching visitor details:', error);
      }
    };

    fetchVisitorDetails();
  }, []);

  return (
    <main>
      <Hero />

      <section className="p-6 xxl:p-12 xl:p-12 lg:p-12 md:p-6 sm:p-6 xs:p-6 my-7">
        <Products />
      </section>

      <section>
        <Banner />
      </section>

      <section className="p-6 xxl:p-12 xl:p-12 lg:p-12 md:p-6 sm:p-6 xs:p-6">
        <Benefits />
      </section>

      <section className="p-6 xxl:p-0 xl:p-0 lg:p-0 md:p-6 sm:p-6 xs:p-6 my-16">
        <Membership />
      </section>
    </main>
  );
};

export default HomePage;
