import React from 'react';
import { withTranslation } from 'react-i18next';
import { Result } from 'antd';

const SuccessPayment = ({ t }) => {
  return (
    <div>
        <Result
          status="success"
          title={t('transactionSuccessful')}
        />
    </div>
  );
};

export default withTranslation()(SuccessPayment);
