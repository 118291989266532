import React from "react";
import OfferDetails from "../../components/OfferDetails";
import { useQuery } from "@tanstack/react-query";
import { queryKeys } from "../../services/react-query/queryKeys";
import { offerDetails } from "../../network/offer";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";
import SpinLoading from "../../components/Common/SpinLoading";

const OfferDetailsPage = () => {
  const { i18n } = useTranslation();
  const location = useLocation();
  const code = location.state;

  const { data: offerData, isLoading } = useQuery({
    queryKey: [queryKeys.listOffers, code, i18n],
    queryFn: () => offerDetails(code),
    enabled: !!i18n && !!code,
  });

  if (isLoading) {
    return <SpinLoading />;
  }

  return (
    <OfferDetails data={offerData?.data} />
  );
};

export default OfferDetailsPage;