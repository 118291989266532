import { useRoutes } from "react-router-dom";
import { getStoredToken } from "../services/user-storage";
import HomePage from "../pages/Home";
import NotFoundPage from "../pages/NotFound";
import routesAuthentication from "./routesAuthentication";
import routesOrders from "./routesOrders";
import routesSettings from "./routesSettings";
import routesProduct from "./routesProduct";
import routesOffer from "./routesOffer";
import PrivacyPage from "../pages/Privacy";
import routesPopularProduct from "./routesPopularProduct";
import ContactPage from "../pages/Contact";
import ComingSoonPage from "../pages/ComingSoon";
import routesCart from "./routesCart";
import ProtectedRoute from "../components/ProtectedRoute";
import routesFavorite from "./routesFavorite";
import CategoriesPage from "../pages/Category";
import Main from "../components/Layout/Main";

function RoutesWrapper() {
  const isAuthenticated = getStoredToken() !== null;
  
  const routes = useRoutes([
    {
      path: "*",
      element: <NotFoundPage />,
    },
    {
      path: "/",
      element: <Main />,
      children: [
        {
          path: "/",
          element: <HomePage />,
        },
        {
          path: "/contact",
          element: <ContactPage />,
        },
        {
          path: "/coming-soon",
          element: <ComingSoonPage />,
        },
        {
          path: "/privacy-policy",
          element: <PrivacyPage />,
        },
        {
          path: "/categories",
          element: <CategoriesPage />,
        },
        ...routesAuthentication,
        ...routesProduct,
        ...routesOffer,
        ...routesPopularProduct,

        // Protected routes (Only accessible if authenticated)
        {
          element: <ProtectedRoute isAuthenticated={isAuthenticated} />,
          children: [
            ...routesOrders,
            ...routesCart,
            ...routesFavorite,
            ...routesSettings
          ],
        },
      ],
    },
  ]);

  return routes;
}

export default RoutesWrapper;
